<template>
  <div class="list_project">
    <div class="list_project__box">
      <div class="list_project__box__content">
        <div class="list_project__box__content__tabs">
          <router-link
            class="tab-item"
            :to="{ path: route.ORGANIZATION_DASHBOARD_PROJECTS }"
          >
            プロジェクト一覧
          </router-link>
          <router-link
            class="tab-item"
            :to="{ path: route.ORGANIZATION_DASHBOARD_INCENTIVES }"
            v-if="false"
          >
            オファー一覧
          </router-link>
        </div>
        <div class="list_project__box__content__table">
          <div class="list_project__box__content__table__title">
            <p>マイプロジェクト</p>
            <button
              v-if="!loadingCheckActiveTeamSelect"
              :class="{
                'btn-sm btn-blue': !isDisabled,
                'btn-sm btn-light-gray shadow': isDisabled,
                }"
              :disabled="isDisabled"
              @click="goCreateProject"
            >
              + 新規プロジェクトを作成
            </button>
          </div>
          <div
            class="list_project__box__content__table__filter"
          >
            <form @submit.prevent="">
              <div class="form-ctrl">
                <input
                  type="text"
                  v-model="state.keyword"
                  @input="onInputKeyword"
                  @keyup.enter="onInputKeyword"
                  placeholder="プロジェクト名を入力"
                />
              </div>
              <div class="form-ctrl">
                <select name="status" @change="onChangeMatchingStatus">
                  <option value="">すべて</option>
                  <option value="14">未承認</option>
                  <option value="13">チーム未選択</option>
                  <option value="11">実施中</option>
                  <option value="12">終了</option>
                  <option value="19">一時保存</option>
                  <option value="15">差戻し</option>
                  <option value="20">停止</option>
                </select>
              </div>
            </form>
          </div>
          <div
            class="list_project__box__content__table__detail"
          >
            <table>
              <thead>
                <tr>
                  <th>
                    <a
                      :class="{
                        active:
                          state.fieldSortName == 'title' &&
                          state.fieldSortType == 'asc',
                      }"
                      @click="sortTable('title')"
                      >プロジェクト名</a
                    >
                  </th>
                  <th>
                    <a
                      :class="{
                        active:
                          state.fieldSortName == 'progress_status' &&
                          state.fieldSortType == 'asc',
                      }"
                      @click="sortTable('progress_status')"
                      >プロジェクトステータス</a
                    >
                  </th>
                  <th>
                    <a
                        :class="{
                        active:
                          state.fieldSortName == 'category_name' &&
                          state.fieldSortType == 'asc',
                      }"
                        @click="sortTable('category_name')"
                    >相談パターン</a
                    >
                  </th>
                </tr>
              </thead>
              <tbody v-if="state.listProjects.length > 0">
                <tr
                  v-for="project in state.listProjects"
                  v-bind:key="project.project_id"
                >
                  <td>
                    <router-link
                      :to="{ path: route.PROJECT_DETAIL + project.project_id }"
                      >{{ project.title || ""}}</router-link
                    >
                  </td>
                  <td>
                    {{ project.matching_status_name || ""}}
                  </td>
                  <td>
                    {{ project.category_name || ""}}
                  </td>
                  <td class="tools" v-if="false">
                    <a class="dots" @click.stop="showDropTool(project.project_id)"></a>
                    <div
                      class="drop-tools"
                      v-if="state.droptool == project.project_id"
                      v-click-outside="hideDropTool"
                    >
                      <a>内容の編集</a>
                      <a>コピーして作成</a>
                      <a>非表示にする</a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              v-show="state.listProjects.length < state.totalProjects"
              class="btn-s btn-next-light-blue shadow btn-load-more"
              @click="loadMoreProjects"
              :disabled="state.loading"
            >
              <i v-if="state.loading" class="fas fa-circle-notch fa-spin"></i>
              続きを見る
            </button>
          </div>
          <div
            class="list_project__box__content__table__detail__no_data"
            v-if="state.listProjects.length == 0 && !state.preload"
          >
            {{state.noData ? "プロジェクトはまだ作成されてません" : "検索結果のデータがありません。"}}
          </div>
          <TablePreload v-if="state.preload" :row="10"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="@/presentation/views/organization/dashboard/list-projects/ListProjects.ts" scoped>
</script>

<style lang="scss" src="@/presentation/views/organization/dashboard/list-projects/ListProjects.scss" scoped>
</style>
