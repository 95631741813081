import { reactive, defineComponent, onMounted, watch, computed } from "vue";
import { useRouter } from "vue-router";
import routePath from "@/commons/RoutePath";
import DIContainer from "@/core/DIContainer";
import OrganizationProjectInteractor from "@/domain/usecases/OrganizationProjectInteractor";
import CommonService from "@/services/CommonService";
import TablePreload from "@/presentation/components/preloader/table-preload/TablePreload.vue";
import { useStore } from "vuex";
export default defineComponent({
    name: "ListProjects",
    components: {
        TablePreload: TablePreload
    },
    setup: function () {
        var route = routePath;
        var router = useRouter();
        var store = useStore();
        var organizationProjectInteractor = DIContainer.instance.get(OrganizationProjectInteractor);
        var state = reactive({
            listProjects: [],
            totalProjects: 0,
            projectPerPage: 50,
            page: 1,
            fieldSortName: "",
            fieldSortType: "",
            status: "",
            matchingStatus: "",
            keyword: "",
            loading: false,
            droptool: null,
            preload: true,
            noData: false,
            isDisabled: false,
            loadingCheckActiveTeamSelect: true,
        });
        var id = null;
        var isDisabled = computed(function () { return store.state.isDisabled; });
        var loadingCheckActiveTeamSelect = computed(function () { return state.loadingCheckActiveTeamSelect; });
        function goCreateProject() {
            store.dispatch('checkActiveTeamSelect').then(function () {
                if (!isDisabled.value) {
                    router.push(routePath.ORGANIZATION_DASHBOARD_CREATE_PROJECT);
                }
            });
        }
        watch(function () { return store.state.isDisabled; }, function (newVal) {
            state.isDisabled = newVal;
        }, {
            immediate: true
        });
        function convertDatetime(date) {
            if (!date) {
                return "";
            }
            var d = new Date(date), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();
            if (month.length < 2) {
                month = '0' + month;
            }
            if (day.length < 2) {
                day = '0' + day;
            }
            return [year, month, day].join('/');
        }
        function getListProjects(action) {
            var param = {
                "filter[organization]": id,
                "keyword": state.keyword,
                "order[column]": state.fieldSortName,
                "order[type]": state.fieldSortType,
                "limit": state.projectPerPage,
                "page": state.page,
                "filter[matching_status][]": state.matchingStatus ? state.matchingStatus : null,
                "filter[status]": state.status ? [state.status] : null,
            };
            if (action == "loadMore") {
                state.loading = true;
            }
            return organizationProjectInteractor.getListOrganizationProject(param).then(function (result) {
                if (!result.data || result.total_count == 0) {
                    state.listProjects = [];
                    state.totalProjects = 0;
                    state.loading = false;
                    state.preload = false;
                    state.noData = action == "onMounted" ? true : false;
                    return;
                }
                ;
                result.data.forEach(function (project) {
                    if (project.working_period_start_at && project.working_period_end_at) {
                        project.working_period_convert = convertDatetime(project.working_period_start_at) + " ~ " + convertDatetime(project.working_period_end_at);
                    }
                    else if (project.working_period_start_at && !project.working_period_end_at) {
                        project.working_period_convert = convertDatetime(project.working_period_start_at);
                    }
                    else if (!project.working_period_start_at && project.working_period_end_at) {
                        project.working_period_convert = convertDatetime(project.working_period_end_at);
                    }
                    else {
                        project.working_period_convert = "";
                    }
                });
                if (action == "getNew" || action == "onMounted") {
                    state.listProjects = result.data;
                    state.preload = false;
                }
                else {
                    state.listProjects = state.listProjects.concat(result.data);
                }
                state.totalProjects = result.total_count;
                state.loading = false;
                state.noData = false;
            }).catch(function (error) {
                state.loading = false;
                state.preload = false;
                state.noData = action == "onMounted" ? true : false;
            });
        }
        function changeProjectPerPage(number) {
            state.projectPerPage = number;
            localStorage.setItem("projectPerPage", number);
            state.page = 1;
            getListProjects("getNew");
        }
        function loadMoreProjects() {
            state.page++;
            getListProjects("loadMore");
        }
        var timeout = null;
        function onInputKeyword(event) {
            state.page = 1;
            state.keyword = event.target.value;
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(function () {
                getListProjects("getNew");
            }, 1000);
        }
        function onChangeMatchingStatus(event) {
            state.page = 1;
            state.matchingStatus = event.target.value;
            getListProjects("getNew");
        }
        function sortTable(field) {
            if (field === state.fieldSortName) {
                state.fieldSortType = state.fieldSortType === "desc" ? "asc" : "desc";
            }
            else {
                state.fieldSortType = "asc";
            }
            state.fieldSortName = field;
            state.page = 1;
            getListProjects("getNew");
        }
        function showDropTool(id) {
            state.droptool = state.droptool != id ? id : null;
        }
        function hideDropTool() {
            state.droptool = null;
        }
        onMounted(function () {
            var user = CommonService.getCookie("user");
            var user_infor = user ? JSON.parse(decodeURIComponent(user)) : null;
            id = user_infor && user_infor['organization_id'] ? user_infor['organization_id'] : null;
            var projectPerPage = localStorage.getItem("projectPerPage");
            state.projectPerPage = projectPerPage ? parseInt(projectPerPage) : 10;
            getListProjects("onMounted");
            store.dispatch('checkActiveTeamSelect').then(function () {
                state.loadingCheckActiveTeamSelect = false;
            });
        });
        return {
            state: state,
            route: route,
            goCreateProject: goCreateProject,
            changeProjectPerPage: changeProjectPerPage,
            loadMoreProjects: loadMoreProjects,
            onInputKeyword: onInputKeyword,
            onChangeMatchingStatus: onChangeMatchingStatus,
            sortTable: sortTable,
            hideDropTool: hideDropTool,
            showDropTool: showDropTool,
            isDisabled: isDisabled,
            loadingCheckActiveTeamSelect: loadingCheckActiveTeamSelect
        };
    }
});
